import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { contentTexts, contentTextsSmart } from '../../../data/content.js'

import CardFlip from '../../Shared/CardFlip';

const Content = ({ title, desc, course }) => {

  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: {dir: {regex: "/images/svg/content/"}}) {
          edges {
            node {
              publicURL,
              name
            }
          }
        }
      }
    `
  )

  data.images.edges.sort((a, b) => a.node.name > b.node.name ? 1 : ((b.node.name > a.node.name) ? -1 : 0))

  return (
    <div className="bg-gray-100 py-10 text-center">
      <div className="text-4xl md:px-60 text-primary text-center font-bold md:leading-8 raleway">{title || 'A melhor preparação para conquistar seu futuro!'}</div>
      <div className="text-2xl font-semibold md:px-60 mt-5">
        {desc ||
          'Aqui pensamos e preparamos cada passo da sua trajetória, pensando no melhor aproveitamento para você chegar ao seu objetivo'
        }</div>

      <div className="grid gap-10 md:grid-cols-4 md:gap-20 md:px-26 px-10 py-10">

        {course !== 'Extensivo Smart' && contentTexts.map((content, index) => (
          <CardFlip key={index} icon={data.images.edges[index].node.publicURL} text={content.text} label={content.label} />
        ))}

        {course === 'Extensivo Smart' && contentTextsSmart.map((content, index) => (
          <CardFlip key={index} icon={data.images.edges[index].node.publicURL} text={content.text} label={content.label} />
        ))}

      </div>
    </div>
  )
}

export default Content
