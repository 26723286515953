import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import phone from '../../../images/svg/phone.svg';
import pin from '../../../images/svg/pin.svg';

const Location2 = () => {

  const mapImage = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "location.PNG" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const locationImage = getImage(mapImage.img.childImageSharp.gatsbyImageData);

  return (
    <div>
      <div className="grid md:grid-cols-2 items-center gap-5 md:px-50">
        <div className="col-span-1">
          <div className="flex gap-5 align-top">
            <img className="w-10 h-12" src={pin} alt="Localização" />
            <div>
              <div className="text-gray-600 text-3xl font-bold leading-6 mb-2">
                Palhoça
              </div>
              <div className="text-gray-600 text-xl font-semibold leading-6 mb-2">
                Endereço: Rua Belarmino Antônio da Silva, 20 – Centro, Palhoça – SC - CEP 88131-010
              </div>
            </div>
          </div>
          <div className="flex gap-5 items-center mt-10">
            <img className="w-10 h-10" src={phone} alt="Celular" />
            <div className="text-gray-600 text-3xl font-bold leading-6 mb-2">
              <a href="https://api.whatsapp.com/send?phone=554888111499%20&text=Ol%C3%A1%2C%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20Pr%C3%B3%20Floripa." target="_blank" rel="noreferrer">
                (48) 9 8811-1499
              </a>
            </div>
          </div>
          <div className="flex gap-5 items-center mt-10">
            <img className="w-10 h-10" src={phone} alt="Celular" />
            <div className="text-gray-600 text-3xl font-bold leading-6 mb-2">
              <a href="tel:+554833073007">
                (48) 3307-3007
              </a>
            </div>
          </div>
          </div>
        <div className="col-span-1">
          <GatsbyImage imgClassName="rounded-3xl" image={locationImage} alt={'Localização'} />
        </div>
      </div>
    </div>
  )
}

export default Location2
