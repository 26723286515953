export const contentTexts = [
  {
    text: "Temos aulas regulares de segunda a sexta-feira, abordando todos os conteúdos exigidos nas principais provas do Brasil.",
    label: "Aulas regulares",
  },
  {
    text: "Aulas com revisão dos conteúdos mais importantes, para auxiliar na fixação da matéria.",
    label: "Aulas extras aos sábados",
  },
  {
    text: "Aulas com temáticas específicas, trazendo assuntos atualizados para ampliar o aprendizado.",
    label: "Aulas especiais e de aprofundamento",
  },
  {
    text: "Onde o aluno pode tirar suas dúvidas relacionadas ao conteúdo e referente aos exercícios que realizou.",
    label: "Monitorias de todas as  disciplinas",
  },
  {
    text: "Um plano de estudo desenvolvido só para você, onde é possível customizar aulas e atividades extras.",
    label: "Plano de estudos individual",
  },
  {
    text: "Desenvolvido pelos próprios professores especialistas em ENEM e UFSC, o que facilita muito no desenvolvimento da aula e apropriação do conteúdo.",
    label: "Material didático próprio",
  },
  {
    text: "Nossa equipe vai ajudar a organizar sua rotina de estudos e acompanhar sua evolução, orientando o aluno em encontros semanais.",
    label: "Acompanhamento pedagógico",
  },
  {
    text: "Encontro quinzenais com a nossa psicóloga especialista em planejamento de estudos e orientação de carreira",
    label: "Acompanhamento psicológico",
  },

]

export const contentTextsSmart = [
  {
    text: "Temos aulas de segunda à quinta-feira, abordando todos os conteúdos do Ensino Médio, ENEM e dos principais vestibulares do Brasil.",
    label: "Aulas",
  },
  {
    text: "Todas as sextas-feiras, temos as nossas monitorias e atendimento aos alunos para tirar dúvidas e ajudar no Plano de Estudos.",
    label: "Monitorias",
  },
  {
    text: "Aulas com temáticas específicas, trazendo assuntos atualizados para ampliar o aprendizado.",
    label: "Aulas especiais e de aprofundamento",
  },
  {
    text: "Onde o aluno pode tirar suas dúvidas relacionadas ao conteúdo e referente aos exercícios que realizou.",
    label: "Monitorias de todas as  disciplinas",
  },
  {
    text: "Um plano de estudo desenvolvido só para você, onde é possível customizar aulas e atividades extras.",
    label: "Plano de estudos individual",
  },
  {
    text: "Desenvolvido pelos próprios professores especialistas em ENEM e UFSC, o que facilita muito no desenvolvimento da aula e apropriação do conteúdo.",
    label: "Material didático próprio",
  },
  {
    text: "Nossa equipe vai ajudar a organizar sua rotina de estudos e acompanhar sua evolução, orientando o aluno em encontros semanais.",
    label: "Acompanhamento pedagógico",
  },
  {
    text: "Encontro quinzenais com a nossa psicóloga especialista em planejamento de estudos e orientação de carreira",
    label: "Acompanhamento psicológico",
  },

]