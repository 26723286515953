import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';


const CardFlip = props => {

  const [flipped, setFlipped] = useState(false);


  return (
    <div className="h-50"
      role="presentation"
      onMouseLeave={() => setFlipped(false)}
      onMouseEnter={() => setFlipped(true)}
    >
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal" containerClassName="h-full">
        <div className="shadow-md rounded-3xl p-5 h-full text-center bg-white" >
          <img className="mx-auto mb-5" src={props.icon} alt="Ícone" />
          <div className="font-semibold text-lg text-center">{props.label}</div>
        </div>

        <div
          className="shadow-md rounded-3xl p-5 h-full text-center bg-primary text-white 
          font-semibold text-lg flex items-center justify-center leading-5">
          {props.text}
        </div>
      </ReactCardFlip>
    </div>
  )
}

export default CardFlip;